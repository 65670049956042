"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {values:[[]], content: {}, isOpened: false, favouriteNumbers: [], selectedCardIndex: undefined };

export const showFavouritesSlice = createSlice({
    name: "showFavourites",
    initialState,
    reducers: {
        setShowFavouritesContent(state, action) {
            state.content = action.payload;
        },
        setShowFavouritesIsOpened(state, action) {
            const { value, lineNumber } = action.payload || {};
            state.isOpened = action.payload.value;
            if(lineNumber)
            state.selectedCardIndex = lineNumber;
        },
        setShowFavouritesNumbers(state, action) {
            let list = action?.payload || [];
            if (!Array.isArray(list) || list?.length < 1)
                list = state.favouriteNumbers;
            state.favouriteNumbers = list;
        },
        resetFavouriteNumbersValues(state) {
            state.values = [[]];
            state.favouriteNumbers = [];
        },
        unsetShowFavouritesNumbers(state, action) {
            state.favouriteNumbers = [];
        },
        addShowFavouritesNumbers(state, action) {
            const list = [...state.favouriteNumbers];
            const newFavourites = action?.payload || {};

            if (!!newFavourites?.displayName && !!newFavourites?.numbers)
                list.push(newFavourites);

            state.favouriteNumbers = list;
        },
        
        setActiveCardFav(state, action) {
            let newLineCards = action?.payload || {};
            if (
                !!parseInt(action?.payload?.index) > -1 &&
                Array.isArray(action?.payload?.values) &&
                !!parseInt(action?.payload?.totalCombination) > 0
            ) {
                const {values} = action.payload;
                newLineCards = values;
                
            }
            state.values = newLineCards;
        },
    },
});

export default showFavouritesSlice.reducer;

export const {
    setShowFavouritesContent,
    setShowFavouritesIsOpened,
    setShowFavouritesNumbers,
    unsetShowFavouritesNumbers,
    addShowFavouritesNumbers,
    resetFavouriteNumbersValues,
    setActiveCardFav,
} = showFavouritesSlice.actions;

export const selectShowFavouritesContent = (state) =>
    state.showFavourites.content;
export const selectShowFavouritesIsOpened = (state) =>
    state.showFavourites.isOpened;
export const selectShowFavouritesNumbers = (state) =>
    state.showFavourites.favouriteNumbers;
export const selectShowFavouriteCardIndex = (state) => state.showFavourites.selectedCardIndex;
export const selectShowFavourites = (state) => state.showFavourites;
export const selectActiveFavCards = (state) => state.showFavourites.values;

