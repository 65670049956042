"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    value: false,
    
    
 };

export const showLoaderSlice = createSlice({
    name: "showLoader",
    initialState,
    reducers: {
        setShowLoader(state, action) {
            state.value = action.payload;
        },
       
    },
});

export default showLoaderSlice.reducer;

export const { setShowLoader   } = showLoaderSlice.actions;

export const selectShowLoader = (state) => state.showLoader.value;


