"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { content: {}, isOpened: false, updateStatus: "" };

export const timeOutConfirmationSlice = createSlice({
    name: "timeOutConfirmation",
    initialState,
    reducers: {
        setTimeOutConfirmationContent(state, action) {
            state.content = action.payload;
        },
        setTimeOutConfirmationIsOpened(state, action) {
            state.isOpened = action.payload;
        },
        setTimeOutConfirmationUpdateStatus(state, action) {
            state.updateStatus = action.payload;
        },
    },
});

export default timeOutConfirmationSlice.reducer;

export const {
    setTimeOutConfirmationContent,
    setTimeOutConfirmationIsOpened,
    setTimeOutConfirmationUpdateStatus,
} = timeOutConfirmationSlice.actions;

export const selectTimeoutConfirmationContent = (state) =>
    state.timeOutConfirmation.content;
export const selectTimeoutConfirmationIsOpened = (state) =>
    state.timeOutConfirmation.isOpened;
export const selectTimeoutConfirmationUpdateStatus = (state) =>
    state.timeOutConfirmation.updateStatus;
export const selectTimeoutConfirmation = (state) => state.timeOutConfirmation;
