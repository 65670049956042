"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: false };

export const readyStoreSlice = createSlice({
    name: "readyStore",
    initialState,
    reducers: {
        setReadyStore(state, action) {
            let value = action.payload || false;
            if (typeof value !== "boolean") value = false;
            state.value = value;
        },
    },
});

export default readyStoreSlice.reducer;

export const { setReadyStore } = readyStoreSlice.actions;

export const selectReadyStore = (state) => state.readyStore.value;
