"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {}, data: {} };

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setAuthUser(state, action) {
      state.value = { ...state.value, ...action.payload };
    },
    unsetAuthUser(state, action) {
      state.value = action.payload;
    },
    setGTMVal(state, action) {
      state.data = action.payload;
    },
  },
});

export default authUserSlice.reducer;

export const { setAuthUser, unsetAuthUser, setGTMVal } = authUserSlice.actions;

export const selectAuthUser = (state) => state.authUser.value;
