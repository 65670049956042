"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { content: {}, isOpened: false, updateStatus: "" };

export const closeAccountConfirmationSlice = createSlice({
    name: "closeAccountConfirmation",
    initialState,
    reducers: {
        setCloseAccountConfirmationContent(state, action) {
            state.content = action.payload;
        },
        setCloseAccountConfirmationIsOpened(state, action) {
            state.isOpened = action.payload;
        },
        setCloseAccountConfirmationUpdateStatus(state, action) {
            state.updateStatus = action.payload;
        },
    },
});

export default closeAccountConfirmationSlice.reducer;

export const {
    setCloseAccountConfirmationContent,
    setCloseAccountConfirmationIsOpened,
    setCloseAccountConfirmationUpdateStatus,
} = closeAccountConfirmationSlice.actions;

export const selectCloseAccountConfirmationContent = (state) =>
    state.closeAccountConfirmation.content;
export const selectCloseAccountConfirmationIsOpened = (state) =>
    state.closeAccountConfirmation.isOpened;
export const selectCloseAccountConfirmationUpdateStatus = (state) =>
    state.closeAccountConfirmation.updateStatus;
export const selectCloseAccountConfirmation = (state) =>
    state.closeAccountConfirmation;
