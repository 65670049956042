"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { content: {}, isOpened: false, uploadStatus: "" };

export const nonPassportUploadSlice = createSlice({
    name: "nonPassportUpload",
    initialState,
    reducers: {
        setNonPassportUploadContent(state, action) {
            state.content = action.payload;
        },
        setNonPassportUploadIsOpened(state, action) {
            state.isOpened = action.payload;
        },
        setNonPassportUploadUpdateStatus(state, action) {
            state.updateStatus = action.payload;
        },
    },
});

export default nonPassportUploadSlice.reducer;

export const {
    setNonPassportUploadContent,
    setNonPassportUploadIsOpened,
    setNonPassportUploadUpdateStatus,
} = nonPassportUploadSlice.actions;

export const selectNonPassportUploadContent = (state) =>
    state.nonPassportUpload.content;
export const selectNonPassportUploadIsOpened = (state) =>
    state.nonPassportUpload.isOpened;
export const selectNonPassportUploadUpdateStatus = (state) =>
    state.nonPassportUpload.uploadStatus;
export const selectNonPassportUpload = (state) => state.nonPassportUpload;
