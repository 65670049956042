"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  duration: [],
  days: [],
  frequency: [],
  purchaseCartId: null,
  isCheckedUseAvailableBalance: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart(state, action) {
      let newItems = [...state.value];
      if (Array.isArray(action?.payload)) {
        newItems = [...action.payload];
      }

      state.value = newItems;
    },
    emptyCart(state, action) {
      state.value = [];
    },
    setDurations(state, action) {
      let newItems = [...state.value];
      if (Array.isArray(action?.payload)) {
        newItems = [...action.payload];
      }

      state.duration = newItems;
    },
    setDays(state, action) {
      let newItems = [...state.value];
      if (Array.isArray(action?.payload)) {
        newItems = [...action.payload];
      }

      state.days = newItems;
    },
    setFrequency(state, action) {
      let newItems = [...state.value];
      if (Array.isArray(action?.payload)) {
        newItems = [...action.payload];
      }

      state.frequency = newItems;
    },
    setPurchaseCartId(state, action) {
      state.purchaseCartId = action.payload;
    },
    setUseAvailableBalance(state, action) {
      state.isCheckedUseAvailableBalance = action.payload;
    },
  },
});

export default cartSlice.reducer;

export const {
  setCart,
  emptyCart,
  setFrequency,
  setDays,
  setDurations,
  setPurchaseCartId,
  setUseAvailableBalance,
} = cartSlice.actions;

export const selectCart = (state) => state.cart.value;
export const selectDuration = (state) => state.cart.duration;
export const selectFrequency = (state) => state.cart.frequency;
export const selectDays = (state) => state.cart.days;
export const selectPurchaseCartId = (state) => state.cart.purchaseCartId;
export const getUseAvailableBalance = (state) =>
  state.cart.isCheckedUseAvailableBalance;
