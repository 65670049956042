"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: { country: "", ipAddress: "", timezone: "" } };

export const geolocationSlice = createSlice({
    name: "geolocation",
    initialState,
    reducers: {
        setGeolocation(state, action) {
            state.value = { ...state.value, ...action.payload };
        },
        unsetGeolocation(state, action) {
            state.value = { country: "", ipAddress: "", timezone: "" };
        },
        setCountry(state, action) {
            let geolocation = { ...state.value };
            geolocation.country =
                typeof action?.payload === "string" ? action?.payload : "";
            state.value = geolocation;
        },
        setIpAddress(state, action) {
            let geolocation = { ...state.value };
            geolocation.ipAddress =
                typeof action?.payload === "string" ? action?.payload : "";
            state.value = geolocation;
        },
        setTimezone(state, action) {
            let geolocation = { ...state.value };
            geolocation.timezone =
                typeof action?.payload === "string" ? action?.payload : "";
            state.value = geolocation;
        },
    },
});

export default geolocationSlice.reducer;

export const {
    setGeolocation,
    unsetGeolocation,
    setCountry,
    setIpAddress,
    setTimezone,
} = geolocationSlice.actions;

export const selectGeolocation = (state) => state.geolocation.value;
export const selectCountry = (state) => state.geolocation.value.country;
export const selectIpAddress = (state) => state.geolocation.value.ipAddress;
export const selectTimezone = (state) => state.geolocation.value.timezone;
