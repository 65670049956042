"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { content: {}, isOpened: false, favouriteNumbers: [] };

export const addFavouritesSlice = createSlice({
    name: "addFavourites",
    initialState,
    reducers: {
        setAddFavouritesContent(state, action) {
            state.content = action.payload;
        },
        setAddFavouritesIsOpened(state, action) {
            state.isOpened = action.payload;
        },
        setAddFavouritesNumbers(state, action) {
            let favouriteNumbers = action?.payload || [];
            if (
                !Array.isArray(favouriteNumbers) ||
                favouriteNumbers?.length < 1
            )
                favouriteNumbers = state.favouriteNumbers;
            state.favouriteNumbers = favouriteNumbers;
        },
    },
});

export default addFavouritesSlice.reducer;

export const {
    setAddFavouritesContent,
    setAddFavouritesIsOpened,
    setAddFavouritesNumbers,
} = addFavouritesSlice.actions;

export const selectAddFavouritesContent = (state) =>
    state.addFavourites.content;
export const selectAddFavouritesIsOpened = (state) =>
    state.addFavourites.isOpened;
export const selectAddFavouritesNumbers = (state) =>
    state.addFavourites.favouriteNumbers;
export const selectAddFavourites = (state) => state.addFavourites;
