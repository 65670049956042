"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
};

export const showToasterSlice = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    showToaster: (state, action) => {
      state.show = true;
      state.message = action.payload;
    },
    hideToaster: (state) => {
      state.show = false;
      state.message = "";
    },
  },
});

export default showToasterSlice.reducer;

export const { showToaster, hideToaster } = showToasterSlice.actions;
