"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: "" };

export const csrfTokenSlice = createSlice({
	name: "csrfToken",
	initialState,
	reducers: {
		setCsrfToken(state, action) {
			state.value = action.payload;
		},
	},
});

export default csrfTokenSlice.reducer;

export const { setCsrfToken } = csrfTokenSlice.actions;

export const selectCsrfToken = (state) => state.csrfToken.value;
