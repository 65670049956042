"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: [],
};

export const gameGroupsSlice = createSlice({
    name: "gameGroups",
    initialState,
    reducers: {
        setGameGroups(state, action) {
            let newItems = [...state.value];
            if (Array.isArray(action?.payload)) {
                newItems = [...action.payload];
            }

            state.value = newItems;
        },
        emptyGameGroups(state, action) {
            state.value = [];
        },
    },
});

export default gameGroupsSlice.reducer;

export const { setGameGroups, emptyGameGroups } = gameGroupsSlice.actions;

export const selectGameGroups = (state) => state.gameGroups.value;
