"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: false };

export const showMobileMenuSlice = createSlice({
    name: "showMobileMenu",
    initialState,
    reducers: {
        setShowMobileMenu(state, action) {
            state.value = action.payload;
        },
    },
});

export default showMobileMenuSlice.reducer;

export const { setShowMobileMenu } = showMobileMenuSlice.actions;

export const selectShowMobileMenu = (state) => state.showMobileMenu.value;
