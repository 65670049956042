"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
});

export default newsSlice.reducer;

export const { setNews } = newsSlice.actions;

export const selectNews = (state) => state.news.value;
