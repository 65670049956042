"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: [[]] };

export const activeLineCardsSlice = createSlice({
    name: "activeLineCards",
    initialState,
    reducers: {
        increaseActiveLineCards(state, action) {
            let newLineCards = [...state.value];
            newLineCards.push([]);
            state.value = newLineCards;
        },
        decreaseActiveLineCards(state, action) {
            let newLineCards = [...state.value];
            if (newLineCards.length > 1) {
                const removedLineCard = newLineCards.pop();
            }
            state.value = newLineCards;
        },
        setActiveLineCardValues(state, action) {
            let newLineCards = [...state.value];
            if (
                !!parseInt(action.payload.index) > -1 &&
                Array.isArray(action.payload.values) &&
                !!parseInt(action.payload.totalCombination) > 0
            ) {
                const { index, values, totalCombination } = action.payload;
                if (!!newLineCards[index]) {
                    if (
                        Array.isArray(values) &&
                        (values.length <= totalCombination ||
                            values.length === 0)
                    ) {
                        // Sort values in ascending order
                        const sortedValues = values.sort((a, b) => a - b);
                        newLineCards[index] = sortedValues;
                        // newLineCards[index] = values;
                    }
                }
            }
            state.value = newLineCards;
        },
        randomizeAllLineCardValues(state, action) {
            let newLineCards = [...state.value];
            if (
                !!parseInt(action.payload.totalCombination) > 0 &&
                !!action.payload.values
            ) {
                const { values, totalCombination } = action.payload;
                if (Array.isArray(values) && values.length > 0) {
                    values.map((value, index) => {
                        if (!!newLineCards[index]) {
                            if (
                                Array.isArray(value) &&
                                value.length === totalCombination
                            ) {
                                // Sort values in ascending order
                                const sortedValues = value.sort(
                                    (a, b) => a - b
                                );
                                newLineCards[index] = sortedValues;
                                // newLineCards[index] = value;
                            }
                        }
                    });
                }
            }

            state.value = newLineCards;
        },
        clearAllLineCardValues(state, action) {
            let newLineCards = [...state.value];
            newLineCards.map((values, index) => {
                if (Array.isArray(values)) {
                    newLineCards[index] = [];
                }
            });
            state.value = newLineCards;
        },
        unsetAllActiveLineCards(state, action) {
            state.value = [[]];
        },
    },
});

export default activeLineCardsSlice.reducer;

export const {
    increaseActiveLineCards,
    decreaseActiveLineCards,
    setActiveLineCardValues,
    randomizeAllLineCardValues,
    clearAllLineCardValues,
    unsetAllActiveLineCards,
} = activeLineCardsSlice.actions;

export const selectActiveLineCards = (state) => state.activeLineCards.value;
