"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: null };

export const counterSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        dispatchCounterDetails(state, action) {
            state.value = action.payload;
        },
    },
});

export default counterSlice.reducer;

export const { dispatchCounterDetails } = counterSlice.actions;

export const getCounterDetails = (state) => state.counter.value;
