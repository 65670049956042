"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { content: {}, isOpened: false, updateStatus: "" };

export const selfExclusionConfirmationSlice = createSlice({
    name: "selfExclusionConfirmation",
    initialState,
    reducers: {
        setSelfExclusionConfirmationContent(state, action) {
            state.content = action.payload;
        },
        setSelfExclusionConfirmationIsOpened(state, action) {
            state.isOpened = action.payload;
        },
        setSelfExclusionConfirmationUpdateStatus(state, action) {
            state.updateStatus = action.payload;
        },
    },
});

export default selfExclusionConfirmationSlice.reducer;

export const {
    setSelfExclusionConfirmationContent,
    setSelfExclusionConfirmationIsOpened,
    setSelfExclusionConfirmationUpdateStatus,
} = selfExclusionConfirmationSlice.actions;

export const selectSelfExclusionConfirmationContent = (state) =>
    state.selfExclusionConfirmation.content;
export const selectSelfExclusionConfirmationIsOpened = (state) =>
    state.selfExclusionConfirmation.isOpened;
export const selectSelfExclusionConfirmationUpdateStatus = (state) =>
    state.selfExclusionConfirmation.updateStatus;
export const selectSelfExclusionConfirmation = (state) =>
    state.selfExclusionConfirmation;
