"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: "en" };

export const localeSlice = createSlice({
    name: "locale",
    initialState,
    reducers: {
        setLocale(state, action) {
            state.value = action.payload;
        },
    },
});

export default localeSlice.reducer;

export const { setLocale } = localeSlice.actions;

export const selectLocale = (state) => state.locale.value;
