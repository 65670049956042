"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { cardDetails: [] };

export const paymentSlice = createSlice({
	name: "payment",
	initialState,
	reducers: {
		setCardDetails(state, action) {
			state.cardDetails = action.payload;
		},
	},
});

export default paymentSlice.reducer;

export const { setCardDetails } = paymentSlice.actions;

export const getCardDetailsSelector = (state) => state.payment.cardDetails;
