"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} ,list:[], details:{} ,path:"" };

export const currencySlice = createSlice({
    name: "currency",
    initialState,
    reducers: {
        setCurrency(state, action) {
            state.value = action.payload;
        },
        setUAECurrency(state,action){
            state.details = action.payload;
        },
        setCurrencyList(state,action){
            state.list = action.payload;

        },
        setBackToPagePath(state,action){
            state.path = action.payload;

        }
    },
});

export default currencySlice.reducer;

export const { setCurrency,setCurrencyList ,setUAECurrency,setBackToPagePath} = currencySlice.actions;

export const selectCurrency = (state) => state.currency.value;
export const getCurrencyList = (state) => state.currency.list;
export const getUAECurrencyDetails = (state) => state.currency.details;
export const getBackToPagePath = (state) => state.currency.path;

